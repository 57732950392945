import gql from 'graphql-tag';

import { client } from 'src/data/api';
import { hasValidationErrors } from 'src/utils/validation';

export const uploadLogo = async (
  organizationID: number,
  logo: File,
): Promise<{ url: string; validationError?: never } | { url?: never; validationError: string }> => {
  const { data, errors } = await client.query<{
    generateOrganizationLogoUploadUrl: {
      url: string;
    };
  }>({
    query: gql`
      query generateOrganizationLogoUploadUrl($organizationID: Int!, $mimeType: String!) {
        generateOrganizationLogoUploadUrl(organizationID: $organizationID, mimeType: $mimeType) {
          url
        }
      }
    `,
    variables: { organizationID, mimeType: logo.type },
    errorPolicy: 'all',
  });

  if (errors) {
    const error = errors[0];
    if (hasValidationErrors(error)) {
      const validationError = error.extensions.validationErrors.map(({ message }) => message).join('. ');
      return { validationError };
    }

    throw error;
  }

  const logoUploadURL = data.generateOrganizationLogoUploadUrl.url;

  await fetch(logoUploadURL, { method: 'PUT', body: logo });

  return { url: logoUploadURL.split('?')[0] };
};

export const updateOrganization = async (orgID: number, orgParams: Record<string, unknown>) =>
  client.mutate<{
    updateOrganization: boolean;
  }>({
    mutation: gql`
      mutation updateOrganization($id: Int!, $organization: UpdateOrganizationInput!) {
        updateOrganization(id: $id, organization: $organization)
      }
    `,
    variables: { id: orgID, organization: orgParams },
    errorPolicy: 'all',
  });
