/* eslint-disable no-use-before-define */

import type { RaRecord, ResourceProps } from 'react-admin';
import type { QueryResponse } from 'ra-data-hasura/dist/buildQuery';
import type { DocumentNode } from 'graphql';

import type { BuildQueryContext, BuildQueryContextWithVariables } from './data/buildQueryFactory';

export type CraftAdminPermission =
  | 'user:read'
  | 'user:edit'
  | 'user:delete'
  | 'company:read'
  | 'company:edit'
  | 'company:delete'
  | 'companyRequest:read'
  | 'companyRequest:edit'
  | 'companyRequest:delete'
  | 'alerts:read'
  | 'alerts:edit'
  | 'alerts:delete'
  | 'organization:read'
  | 'organization:edit'
  | 'organization:delete'
  | 'organization:readLimited'
  | 'api:read'
  | 'api:edit'
  | 'api:delete'
  | 'organizationUser:read'
  | 'organizationUser:edit'
  | 'organizationUser:delete'
  | 'dataPackage:read'
  | 'dataPackage:edit'
  | 'dataPackage:delete'
  | 'dataPackageApiField:read'
  | 'dataPackageApiField:edit'
  | 'dataPackageApiField:delete'
  | 'companySubscriptionSet:read'
  | 'companySubscriptionSet:edit'
  | 'companySubscriptionSet:delete'
  | 'companySubscription:read'
  | 'companySubscription:edit'
  | 'companySubscription:delete';

export type CraftAdminUserPermissions = Set<CraftAdminPermission>;

export interface ExternalApiPolicyRecord extends RaRecord {
  id: number;
  field_type: string;
  available: boolean;
}

export interface ExternalApiRowLevelAccessPolicy extends RaRecord {
  id: number;
  policies: {
    risk?: string;
    locations?: {
      filters?: 'hqOnly'[];
    };
  } | null;
}

export interface ExternalApiClientRecord extends RaRecord {
  id: number;
  token: string | null;
  client_type: string | null;
  requests_limit: number | null;
  created_at: string;
  updated_at: string;
  archived_at: string | null;
  archived_by: string | null;
  policies: Pick<ExternalApiPolicyRecord, 'id' | 'field_type' | 'available'>[];
  row_level_access_policy: ExternalApiRowLevelAccessPolicy | null;
  portal_organizations: Pick<OrganizationRecord, 'id' | 'name'>[];
}

export interface DataPackageApiFieldPolicies {
  sources?: string[];
  filters?: string[];
}

export interface DataPackageApiFieldRecord extends RaRecord {
  id: number;
  api_field_id: number;
  policies: DataPackageApiFieldPolicies | null;
  api_field: {
    id: number;
    path: string;
  };
}

export enum DataPackageKind {
  STANDARD = 'standard',
  CUSTOM = 'custom',
  INTERNAL = 'internal',
}

export interface DataPackageRecord extends RaRecord {
  id: number;
  name: string;
  kind: DataPackageKind;
  is_deprecated: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  api_fields_xrefs: DataPackageApiFieldRecord[];
}

export interface DefaultDataPackageRecord extends RaRecord {
  id: number;
  data_package_id: number;
  created_at: string;
  updated_at: string;
}

export interface ApiFieldRecord extends RaRecord {
  id: number;
  path: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface CompanySubscriptionSetDataPackageRecord extends RaRecord {
  id: number;
  data_package_id: number;
}

interface CompanySubscriptionSetRecordBroad extends RaRecord {
  id: number;
  external_api_client_id: number;
  name: string;
  company_limit: number | null;
  all_companies: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  data_packages_xrefs: CompanySubscriptionSetDataPackageRecord[];
}

export type CompanySubscriptionSetRecord =
  | (CompanySubscriptionSetRecordBroad & {
      company_limit: number;
      all_companies: false;
    })
  | (CompanySubscriptionSetRecordBroad & {
      company_limit: null;
      all_companies: true;
    });

export interface CompanySubscriptionRecord extends RaRecord {
  id: number;
  company_id: number;
  company_subscription_set_id: number;
  created_at: string;
  updated_at: string;
}

export interface OrganizationRecord extends RaRecord {
  id: number;
  alerts: boolean;
  archived_at: string | null;
  brand_color: string;
  british_steel: boolean;
  cb_insights_integration: boolean;
  company_data_api_key: string;
  craft_dashboards_access: boolean;
  craft_dashboards_hosts: string[];
  created_at: string;
  deleted_at: string | null;
  dnb_integration: boolean;
  logo_url: string | null;
  name: string;
  native_risk_dashboard: boolean;
  product_name: string | null;
  risk_dashboard_access: boolean;
  settings_id: number | null;
  sso_enabled: boolean;
  tableau_url: string | null;
  tableau_user: string | null;
  totp_mfa_enabled: boolean;
  type: string;
  updated_at: string;
  settings: {
    data: {
      craftForTeamsSettings?: {
        enabled?: boolean;
        trialStartDate?: string | null;
        trialEndDate?: string | null;
      };
      limits?: {
        enabled?: boolean;
        portfolioLimit?: number | null;
        portfolioItemsLimit?: number | null;
        userAccountLimit?: number | null;
      };
      customView?: {
        enabled?: boolean;
        homePageOverride?: string | null;
        homepageTitle?: string | null;
        homepageMessage?: string | null;
        homepageProductTitle?: string | null;
        homepageProductTitleColor?: string | null;
        hideHomeSidebarLink?: boolean;
        hideHelpCenterLink?: boolean;
        showPoweredByCraft?: boolean;
        hideDefaultSearchSuggestions?: boolean;
        smallLogoUrl?: string | null;
        customSidebarLinks?:
          | {
              enabled: boolean;
              url: string;
              title: string;
              iconUrl: string;
            }[]
          | null;
      };
      featureFlags?: {
        nativeRiskDashboard?: boolean;
        insights?: boolean;
        nTier?: boolean;
        nTierNew?: boolean;
        nTierCentralityScore?: boolean;
        nTierImpact?: boolean;
        sensitive?: boolean;
        firstPartyData?: boolean;
        gsca?: boolean;
        caseManagementEnabled?: boolean;
        caseManagementLocations?: boolean;
        internalMailDomains?: string[] | null;
        riskCategoryField?: boolean;
        riskCategoryChart?: boolean;
        companyRequests?: boolean;
        advancedSearch?: boolean;
        compareCompanies?: boolean;
        totpMFAEnabled?: boolean;
        customJobBusinessUnitEnabled?: boolean;
        maxPortfolioDepth?: number;
        portfolioCheckEnabled?: boolean;
        brandedEmails?: boolean;
      };
      suggestions?:
        | {
            label: string;
            url: string;
          }[]
        | null;
      samlSettings?: {
        certs: string[];
        issuer: string;
        spCerts: string;
        entryPoint: string;
        accountName: string;
      };
      certaSettings?: {
        enabled?: boolean;
        tenantName?: string | null;
      };
      internalOrganizationSettings?: {
        showAdminPanel?: boolean;
      };
      securityScoreCardReportDownload?: {
        enabled?: boolean;
        companySummaryReportEnabled?: boolean;
        companyIssueReportEnabled?: boolean;
      };
      notifications?: { type: string; enabled: boolean }[];
    };
  };
}

export enum ResourceSections {
  COMPANIES = 'companies',
  ALERTS = 'alerts',
  PORTAL = 'portal',
  CLIENT_API = 'Client API',
  SUBSCRIPTIONS = 'subscriptions',
  DATA_PACKAGES = 'Data Packages',
}

export interface ResourcePermissionMap {
  list?: CraftAdminPermission[];
  create?: CraftAdminPermission[];
  edit?: CraftAdminPermission[];
}

export type ExtendedOperationDefinition = {
  operationName?: string;
};

export type IdKeyDefinition = string[];

/**
 * Default React Admin resource with Craft Admin specific extensions
 * to customize the behavior.
 */
export type ResourceDefinition = ResourceProps & {
  /**
   * Defines the sidebar section where the link to the resource
   * will be placed.
   */
  section: ResourceSections;

  /**
   * Defines required permissions for resource
   */
  permissions?: ResourcePermissionMap;

  /**
   * Defines query extensions to be applied when requesting the
   * data for the given resource.
   */
  extension?: {
    /**
     * Certain resources may not have an "id" field per se,
     * you can use this extension to assign a different "id" key,
     * which can be either a single other field: ["org_id"],
     * or a compound of multiple fields: ["org_id", "company_id"].
     *
     * The resulting "id" field will be a string composed of the
     * specified keys.
     *
     * Given config also instructs apollo cache to use this as
     * the keyFields of the GraphQL type representing the resource.
     */
    idKey?: IdKeyDefinition;

    /**
     * Allows to specify custom query fields when they don't match
     * the resource type name (or when they don't match Hasura convention).
     */
    operations?: {
      [fetchType: string]: ExtendedOperationDefinition;
    };

    /**
     * GraphQL Fragment to append to the resource's queries
     * to fetch additional fields alongside the default ones.
     *
     * @example
     * {
     *   extraFields: gql`{ field1 field2 { subfield1 subfield2 } }`
     * }
     */
    extraFields?: DocumentNode;

    /**
     * Allows you to transform React Admin query parameters before
     * they are used to build the GraphQL query.
     * @example
     * (ctx) => {
     *   if (ctx.operation.type === 'GET_LIST') {
     *     // Permanent filter to exclude deleted records
     *     ctx.operation.params.filter['deleted_at@_is_null'] = false;
     *   }
     *
     *   return ctx;
     * }
     */
    transformParams?: (ctx: BuildQueryContext) => BuildQueryContext;

    /**
     * Allows you to transform the Hasura query variables before
     * the query is sent to the API. Happens after variables are transformed
     * from the React Admin operation parameters.
     *
     * @example
     * (ctx) => {
     *   if (ctx.operation.type === 'CREATE') {
     *     Object.assign(ctx.variables.object, { createdAt: new Date() });
     *   }
     *
     *   return ctx;
     * }
     */
    transformVariables?: (ctx: BuildQueryContextWithVariables) => BuildQueryContextWithVariables;

    /**
     * Allows you to transform the response from the API before
     * it is returned to the React Admin data provider.
     * @example
     * (ctx, response) => {
     *   return {
     *     ...response,
     *     data: response.data.map((r) => ({ ...r, name: r.name.toUpperCase() })),
     *   };
     * }
     */
    transformResponse?: (ctx: BuildQueryContextWithVariables, response: QueryResponse) => QueryResponse;

    /**
     * Allows you to transform an individual resource items before
     * it is returned to the React Admin data provider.
     *
     * ⚠️ Might not work as you'd expect for all the operation types,
     * see PLURAL_IDENTIFIER_OPERATION_TYPES in buildQuery.ts
     *
     * @example
     * (ctx, resource) => {
     *   return { ...resource, name: resource.name.toUpperCase() };
     * }
     */
    transformResource?: (ctx: BuildQueryContextWithVariables, resource: QueryResponse['data']) => QueryResponse['data'];

    /**
     * A GraphQL fragment with fields to exclude from the resource's queries, or a function that
     * receives user permissions and returns said fragment. Fields are removed after `extraFields`
     * are appended.
     *
     * @example
     * {
     *   excludeFields: (userPermissions) => gql`
     *     {
     *       field1
     *       field2 {
     *         ${!userPermissions.has('somePermission') ? subfield1 : ''}
     *         subfield2
     *       }
     *     }
     *   `
     * }
     */
    excludeFields?: DocumentNode | ((userPermissions: CraftAdminUserPermissions) => DocumentNode | undefined);
  };
};
