const tooltips = {
  smallLogoUrl: (
    <span>
      This logo is used when the side navigation is collapsed.
      <br />
      <br />
      Keep it empty to use the default Craft logo.
    </span>
  ),
  insights: <span>By enabling this, you will see Insights on the Risk Hub page and Risk tab on the Profile page.</span>,
  craftDashboards: (
    <span>
      Dashboards are currently in Beta. Please, do not enable for external Organizations.
      <br />
      <br />
      Enabling this feature will allow Users to create and sharing customizable dashboards.
    </span>
  ),
  bundleHosts: (
    <span>
      A list of domains which are allowed to host external widget bundles to be loaded on the Craft Dashboards.
      <br />
      <br />
      After adding a host here, Portal will accept and load bundles located there.
    </span>
  ),
  intercomId: <span>Leave blank to use default Craft ID.</span>,
  sensitive: (
    <span>
      Check this box if this Organization represents a Craft client whose name Craft should not share or publicize with
      other parties. For example, one thing this checkbox does is prevent the Account Name (Organization Name) from
      reaching Pendo. Instead, only the Org ID number is shared with Pendo.
    </span>
  ),
  britishSteel: (
    <>
      <span>
        Multi factor chart provides a time series view of revenue, employee headcount, and net income on the company
        overview tab.
      </span>
      <br />
      <br />
      <span>The graph is labeled &quot;Overview summary&quot;.</span>
    </>
  ),
  caseManagementEnabled: (
    <span>Check this box to enable case management for the org. This replaces the existing launch darkly flag</span>
  ),
  customJobBusinessUnitEnabled: (
    <span>Allow users to enter their own job titles &amp; business units instead of selecting from a static list.</span>
  ),
  gsca: <span>Check this box to track a supplier risk for the German Supply Chain Act (GSCA) of 2023</span>,
  caseManagementLocations: (
    <span>
      Check this box to track countries associated with a case. Note: this is not related to the GSCA location field.
    </span>
  ),
  internalMailDomains: (
    <>
      <span>
        These are the domains that will be considered internal for the purposes of case management. For example, if you
        enter &quot;example.com&quot; here, then any email address ending in &quot;@example.com&quot; will be considered
        internal.
      </span>
      <br />
      <br />
      <span>Separate each domain with a comma or new line.</span>
      <br />
      <br />
      <span>You can also list individual user emails if you wish them to be included.</span>
      <br />
      <br />
      <span>DO NOT include craft.co or public emails services (like gmail.com, etc).</span>
    </>
  ),
  ssoJitURL: (
    <span>
      The single sign-on just-in-time (JIT) url is used to add new members to the account without first inviting them
      through Craft. This link will take users directly to their identity provider (IdP) with Craft identifiers embedded
      in the request.
    </span>
  ),
  sso: <span>Note: SSO cannot be enabled for MFA enabled organizations.</span>,
  mfa: <span>Note: MFA cannot be enabled for SSO enabled organizations.</span>,
  homePageOverride: (
    <span>
      The page a user should see when they visit the base Portal URL. You may enter the full URL (e.g.{' '}
      <em>https://portal.craft.co/risk-hub</em>) or the path (e.g. <em>/risk-hub</em>).
      <br />
      <br />
      Keep it empty to use the standard homepage.
    </span>
  ),
  hideHomeSidebarLink: <span>Turn this on to prevent users from seeing the &quot;Home&quot; icon in the sidebar.</span>,
  companySummaryReportEnabled: (
    <span>
      Requires the following fields to be enabled for the API client:
      <br />- homepage
      <br />- securityRatings.factors
    </span>
  ),
  companyIssueReportEnabled: (
    <span>
      Requires the following fields to be enabled for the API client:
      <br />- homepage
      <br />- securityRatings.factors
      <br />- securityRatings.issueSummary
    </span>
  ),
  portfolioCheckEnabled: <span>Enables Portfolio Check for this org. This replaces the LaunchDarkly flag.</span>,
  brandedEmails: <span>Check to use organization&apos;s custom logo and customer name in emails sent from Craft.</span>,
};

export default tooltips;
