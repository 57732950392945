import React, { useState } from 'react';
import { useNotify, useRefresh, TabbedFormProps, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import CraftTabbedForm from 'src/components/CraftTabbedForm';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { editOrganizationSchema } from '../validation';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { updateOrganization, uploadLogo } from '../helpers';
import client from 'src/data/api';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';
import { OrganizationRecord } from 'src/types';

const EditOrganizationsTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  const record = useRecordContext<OrganizationRecord>();
  const notify = useNotify();
  const refresh = useRefresh();
  const [updateLoading, setUpdateLoading] = useState(false);

  const [deleteOrganization, { loading: deleteLoading }] = useMutation<{
    deleteOrganization: boolean;
  }>(
    gql`
      mutation deleteOrganization($id: Int!) {
        deleteOrganization(id: $id)
      }
    `,
    { client, errorPolicy: 'all' },
  );

  // default values which can't be taken directly from resource record
  const defaultValues = record
    ? {
        logo: { src: record.logo_url },
        craft_dashboards_hosts_transformed: record.craft_dashboards_hosts.map((host: string) => ({ value: host })),
        settings: {
          data: {
            samlSettings: record.sso_enabled
              ? {
                  certs_transformed: record.settings.data.samlSettings?.certs?.map((cert: string) => ({ value: cert })),
                }
              : undefined,
            notifications_transformed: record.settings.data.notifications?.reduce((acc, curr) => {
              acc[curr.type] = curr.enabled;
              return acc;
            }, {} as Record<string, boolean>),
          },
        },
      }
    : {};

  const handleSave = async (rawValues: Record<string, unknown>) => {
    if (!record) return undefined;

    const validationResult = editOrganizationSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const settingsData = {
      intercomId: values.settings.data.intercomId,

      craftForTeamsSettings: {
        enabled: values.settings.data.craftForTeamsSettings.enabled,
        trialStartDate: values.settings.data.craftForTeamsSettings.trialStartDate,
        trialEndDate: values.settings.data.craftForTeamsSettings.trialEndDate,
      },

      limits: {
        enabled: values.settings.data.limits.enabled,
        portfolioLimit: values.settings.data.limits.portfolioLimit,
        portfolioItemsLimit: values.settings.data.limits.portfolioItemsLimit,
        userAccountLimit: values.settings.data.limits.userAccountLimit,
      },

      customView: {
        enabled: values.settings.data.customView.enabled,
        homePageOverride: values.settings.data.customView.homePageOverride,
        homepageTitle: values.settings.data.customView.homepageTitle,
        homepageMessage: values.settings.data.customView.homepageMessage,
        homepageProductTitle: values.settings.data.customView.homepageProductTitle,
        homepageProductTitleColor: values.settings.data.customView.homepageProductTitleColor,
        hideHomeSidebarLink: values.settings.data.customView.hideHomeSidebarLink,
        hideHelpCenterLink: values.settings.data.customView.hideHelpCenterLink,
        showPoweredByCraft: values.settings.data.customView.showPoweredByCraft,
        hideDefaultSearchSuggestions: values.settings.data.customView.hideDefaultSearchSuggestions,
        smallLogoUrl: values.settings.data.customView.smallLogoUrl,
        customSidebarLinks: values.settings.data.customView.customSidebarLinks,
      },

      featureFlags: {
        nativeRiskDashboard: values.settings.data.featureFlags.nativeRiskDashboard,
        insights: values.settings.data.featureFlags.insights,
        nTier: values.settings.data.featureFlags.nTier,
        nTierNew: values.settings.data.featureFlags.nTierNew,
        nTierCentralityScore: values.settings.data.featureFlags.nTierCentralityScore,
        nTierImpact: values.settings.data.featureFlags.nTierImpact,
        sensitive: values.settings.data.featureFlags.sensitive,
        firstPartyData: values.settings.data.featureFlags.firstPartyData,
        gsca: values.settings.data.featureFlags.gsca,
        caseManagementEnabled: values.settings.data.featureFlags.caseManagementEnabled,
        caseManagementLocations: values.settings.data.featureFlags.caseManagementLocations,
        internalMailDomains: values.settings.data.featureFlags.internalMailDomains,
        riskCategoryField: values.settings.data.featureFlags.riskCategoryField,
        riskCategoryChart: values.settings.data.featureFlags.riskCategoryChart,
        companyRequests: values.settings.data.featureFlags.companyRequests,
        advancedSearch: values.settings.data.featureFlags.advancedSearch,
        compareCompanies: values.settings.data.featureFlags.compareCompanies,
        totpMFAEnabled: values.settings.data.featureFlags.totpMFAEnabled,
        customJobBusinessUnitEnabled: values.settings.data.featureFlags.customJobBusinessUnitEnabled,
        maxPortfolioDepth: values.settings.data.featureFlags.maxPortfolioDepth,
        portfolioCheckEnabled: values.settings.data.featureFlags.portfolioCheckEnabled,
        brandedEmails: values.settings.data.featureFlags.brandedEmails,
      },

      suggestions: values.settings.data.suggestions,

      samlSettings: values.settings.data.samlSettings && {
        certs: values.settings.data.samlSettings.certs_transformed,
        issuer: values.settings.data.samlSettings.issuer,
        spCerts: values.settings.data.samlSettings.spCerts,
        entryPoint: values.settings.data.samlSettings.entryPoint,
        accountName: values.settings.data.samlSettings.accountName,
      },

      certaSettings: {
        enabled: values.settings.data.certaSettings.enabled,
        tenantName: values.settings.data.certaSettings.tenantName,
      },

      internalOrganizationSettings: {
        showAdminPanel: values.settings.data.internalOrganizationSettings.showAdminPanel,
      },

      securityScoreCardReportDownload: {
        enabled: values.settings.data.securityScoreCardReportDownload.enabled,
        companySummaryReportEnabled: values.settings.data.securityScoreCardReportDownload.companySummaryReportEnabled,
        companyIssueReportEnabled: values.settings.data.securityScoreCardReportDownload.companyIssueReportEnabled,
      },

      notifications: values.settings.data.notifications_transformed,
    };

    // handle logo upload
    let logoURL = values.logo?.rawFile || !values.logo?.src ? null : values.logo.src;
    if (values.logo?.rawFile) {
      // logo is being updated
      try {
        const { url, validationError } = await uploadLogo(record.id as number, values.logo.rawFile);
        // map any validation errors from fetching logo URL to the `logo` form field
        if (validationError) return { logo: validationError };
        logoURL = url ?? null;
      } catch (e) {
        notify('Error uploading logo', { type: 'error' });
        return undefined;
      }
    }

    const orgParams = {
      name: values.name,
      logoUrl: logoURL,
      productName: values.product_name,
      type: values.type,
      craftDashboardsAccess: values.craft_dashboards_access,
      craftDashboardsHosts: values.craft_dashboards_hosts_transformed,
      riskDashboardAccess: values.risk_dashboard_access,
      ssoEnabled: values.sso_enabled,
      britishSteel: values.british_steel,
      alerts: values.alerts,
      settings: { data: settingsData },
    };

    const { errors } = await updateOrganization(record.id as number, orgParams);
    if (errors) {
      notify(
        'Oops. There was a problem updating the organization. Please refresh and try again, or contact IT Support.',
        { type: 'error', autoHideDuration: 10000 },
      );
      console.error('Error response on organization update:', errors);
      return undefined;
    }

    window.scroll(0, 0);
    notify('The organization has been successfully updated', { type: 'success' });
    refresh();

    return undefined;
  };

  const handleSubmit = async (rawValues: Record<string, unknown>) => {
    setUpdateLoading(true);
    const validationErrorMap = await handleSave(rawValues);
    setUpdateLoading(false);
    return validationErrorMap;
  };

  const handleConfirmDelete = async () => {
    if (!record) return undefined;

    try {
      const result = await deleteOrganization({ variables: { id: record.id } });
      notify('The organization has been successfully deleted', { type: 'success' });
      refresh();

      return result;
    } catch (e) {
      notify('There was a problem while deleting the organization', { type: 'error' });
      return false;
    }
  };

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        deleteOptions={{
          deletePermission: 'organization:delete',
          onConfirmDelete: handleConfirmDelete,
          dialogTitle: `Delete Organization ${record && record.name} #${record && record.id}`,
          dialogContent: (
            <>
              <Typography variant="body2" fontWeight="bold" marginBottom={1.5}>
                What happens when an organization is deleted:
              </Typography>

              <Typography variant="body2">
                All organization members will no longer have access to the portal. You will have to recreate an
                organization to grant members access again.
              </Typography>
            </>
          ),
        }}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        shouldUnregister
        {...rest}
      >
        {children}
      </CraftTabbedForm>
      <LoadingOverlay open={updateLoading || deleteLoading} />
      <ArchivedOrDeletedNotification record={record} notificationText="This Organization is ARCHIVED" />
    </>
  );
};

export default EditOrganizationsTabbedForm;
